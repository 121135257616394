import {FC, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField, Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {InfoIcon, LockIcon, TimeIcon} from '@chakra-ui/icons';
import {ModelService} from '../services/model.service';
import {IModel} from '../models/IModel';
import {AxiosError} from 'axios';
import {CreatableSelect} from 'chakra-react-select';
import {useAppSelector} from '../store/hook';

type Props = {
    onSuccess: (model: IModel) => void
}

type AddForm = {
    username: string
    password: string
    smsTimeout: string
    customNum: string
    packCount: string
    packTimeout: string
    proxyIp: string;
    proxyPort: string;
    proxyPassword: string;
    proxyUsername: string;
    admin: string
    isLockMonitoring: boolean
    userId: string
}

const AddModel: FC<Props> = ({onSuccess}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const admins = useAppSelector(state => state.admins.admins);
    const {control, handleSubmit, reset, setError, formState: {errors}} = useForm<AddForm>({
        defaultValues: {
            username: '',
            password: '',
            smsTimeout: '5',
            packCount: '50',
            packTimeout: '5',
            customNum: '',
            proxyIp: '',
            proxyUsername: '',
            proxyPassword: '',
            proxyPort: '',
            admin: '',
            userId: '',
        },
    });

    const [isLoading, setIsLoading] = useState(false);

    const closeModalAddHandler = () => {
        onClose();
        reset();
    };

    const toast = useToast();


    const onSubmit: SubmitHandler<AddForm> = (data) => {
        setIsLoading(true);
        const getAdminValue = (admin: string | { label: string, value: string } | null) => {
            if (typeof admin === 'string') return admin ?? '';
            else return admin?.value ?? '';
        };
        ModelService.add({
            ...data,
            isLockMonitoring: !data.isLockMonitoring,
            admin: getAdminValue(data.admin),
            smsTimeout: +data.smsTimeout,
            packTimeout: +data.packTimeout,
            packCount: +data.packCount,
        })
            .then((res) => {
                onSuccess(res);
                closeModalAddHandler();
            })
            .catch((e) => {
                const err = e as AxiosError<{ message: string }>;
                if (err.response?.data?.message === 'Username exists') {
                    setError('username', {type: 'validate', message: 'Логин уже добавлен'});
                } else
                    toast({
                        status: 'error',
                        title: 'Ошибка',
                        description: 'Произошла ошибка при добавлении новой модели',
                    });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <Tooltip label="Добавить аккаунт модели">
                <Button onClick={onOpen} isLoading={isLoading}>Добавить</Button>
            </Tooltip>
            <Modal isOpen={isOpen} onClose={closeModalAddHandler} returnFocusOnClose={false}>
                <ModalOverlay/>
                <ModalContent className="sm:min-w-[600px]">
                    <ModalHeader className="!text-2xl">Добавление модели</ModalHeader>
                    <ModalCloseButton/>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Controller name="admin" rules={{
                                minLength: {value: 1, message: 'Минимальная длина 1 символ'},
                            }} control={control} render={({field}) => <FormControl isInvalid={!!errors.admin}>
                                <FormLabel>Админ</FormLabel>
                                <CreatableSelect<{ label: string, value: string }>
                                    value={typeof field.value === 'string' ?
                                        field.value === '' ? null : {
                                            label: field.value?.toUpperCase(),
                                            value: field.value?.toLowerCase(),
                                        }
                                        : field.value}
                                    formatCreateLabel={inputValue => `Добавить "${inputValue}"`}
                                    onChange={field.onChange}
                                    selectedOptionStyle="check"
                                    placeholder="Выберите админа или добавьте нового"
                                    focusBorderColor="teal.200" isClearable
                                    options={admins.filter(admin => admin.name !== 'other').map(admin => ({
                                        label: admin.name.toUpperCase(),
                                        value: admin.name.toLowerCase(),
                                    }))}/>
                                <FormErrorMessage>{errors.admin?.message}</FormErrorMessage>
                            </FormControl>}/>
                           <div className="flex items-center justify-between gap-5">
                               <Controller name="customNum" rules={{
                                   required: 'Это обязательное поле',
                                   minLength: {value: 1, message: 'Минимальная длина 1 символ'},
                               }} control={control} render={({field}) => <FormControl isInvalid={!!errors.customNum}
                                                                                      className="mt-4">
                                   <FormLabel>Нумерация</FormLabel>
                                   <InputGroup>
                                       <Input
                                           focusBorderColor="teal.200"
                                           type="text"
                                           placeholder="Введите номер"
                                           value={field.value}
                                           onChange={field.onChange}
                                       />
                                   </InputGroup>
                                   <FormErrorMessage>{errors.customNum?.message}</FormErrorMessage>
                               </FormControl>}/>
                               <Controller name="userId" rules={{
                                   required: 'Это обязательное поле',
                                   minLength: {value: 5, message: 'Минимальная длина 5 символов'},
                               }} control={control} render={({field}) => <FormControl isInvalid={!!errors.userId}
                                                                                      className="mt-4">
                                   <FormLabel>Id</FormLabel>
                                   <InputGroup>
                                       <Input
                                           focusBorderColor="teal.200"
                                           type="text"
                                           placeholder="Введите id"
                                           value={field.value}
                                           onChange={field.onChange}
                                       />
                                   </InputGroup>
                                   <FormErrorMessage>{errors.userId?.message}</FormErrorMessage>
                               </FormControl>}/>
                               <Controller name="isLockMonitoring" defaultValue={true} control={control} render={({field}) => <FormControl isInvalid={!!errors.admin} className="mt-4 flex flex-col justify-end">
                                   <FormLabel>Мониторинг донатов</FormLabel>
                                   <Switch isChecked={field.value} onChange={field.onChange}/>
                                   <FormErrorMessage>{errors.admin?.message}</FormErrorMessage>
                               </FormControl>}/>
                           </div>
                            <Controller name="username" rules={{
                                required: 'Это обязательное поле',
                                minLength: {value: 3, message: 'Минимальная длина 3 символа'},
                            }} control={control} render={({field}) => <FormControl isInvalid={!!errors.username}
                                                                                   className="mt-4">
                                <FormLabel>Логин</FormLabel>
                                <InputGroup>
                                    <Input
                                        focusBorderColor="teal.200"
                                        type="text"
                                        placeholder="Введите логин"
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    <InputRightElement className="flex items-center justify-center !h-full">
                                        <Icon viewBox="0 0 24 24" className="!w-6 !h-6 fill-white">
                                            <path
                                                d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H18C18 18.6863 15.3137 16 12 16C8.68629 16 6 18.6863 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"/>
                                        </Icon>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
                            </FormControl>}/>
                            <Controller name="password" rules={{
                                required: 'Это обязательное поле',
                                minLength: {value: 6, message: 'Минимальная длина 6 символов'},
                            }} control={control} render={({field}) => <FormControl isInvalid={!!errors.password}
                                                                                   className="mt-4">
                                <FormLabel>Пароль</FormLabel>
                                <InputGroup>
                                    <Input
                                        type="password"
                                        focusBorderColor="teal.200"
                                        placeholder="**************"
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    <InputRightElement className="flex items-center justify-center !h-full">
                                        <LockIcon className="!w-6 !h-6"/>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                            </FormControl>}/>
                            <Box className="flex gap-1 max-sm:flex-col">
                                <Controller name="smsTimeout" rules={{
                                    required: 'Это обязательное поле',
                                }} control={control} render={({field}) => <FormControl isInvalid={!!errors.smsTimeout}
                                                                                       className="mt-4">
                                    <FormLabel className="!flex items-center gap-2">
                                        <Text>Смс задержка</Text>
                                        <Tooltip label="Частота отправки смс. Измеряется в секундах">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </FormLabel>
                                    <InputGroup>
                                        <NumberInput focusBorderColor="teal.200" className="w-full"
                                                     min={1} value={field.value} onChange={field.onChange}
                                                     allowMouseWheel clampValueOnBlur>
                                            <NumberInputField/>
                                            <InputRightElement className="flex items-center justify-center !h-full">
                                                <TimeIcon className="!w-6 !h-6"/>
                                            </InputRightElement>
                                        </NumberInput>
                                    </InputGroup>
                                    <FormErrorMessage>{errors.smsTimeout?.message}</FormErrorMessage>
                                </FormControl>}/>
                                <Controller name="packCount" rules={{
                                    required: 'Это обязательное поле',
                                }} control={control} render={({field}) => <FormControl isInvalid={!!errors.smsTimeout}
                                                                                       className="mt-4">
                                    <FormLabel className="!flex items-center gap-2">
                                        <Text>Размер группы смс</Text>
                                        <Tooltip label="Количество смс после которого будет установлена задержка">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </FormLabel>
                                    <InputGroup>
                                        <NumberInput focusBorderColor="teal.200" className="w-full"
                                                     min={1} value={field.value} onChange={field.onChange}
                                                     allowMouseWheel clampValueOnBlur>
                                            <NumberInputField/>
                                            <InputRightElement className="flex items-center justify-center !h-full">
                                                <Icon viewBox="0 0 24 24" className="fill-white !w-6 !h-6">
                                                    <path
                                                        d="M21 3C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.455L2 22.5V4C2 3.44772 2.44772 3 3 3H21ZM20 5H4V18.385L5.76333 17H20V5ZM13 7V15H11V7H13ZM17 9V15H15V9H17ZM9 11V15H7V11H9Z"/>
                                                </Icon>
                                            </InputRightElement>
                                        </NumberInput>
                                    </InputGroup>
                                    <FormErrorMessage>{errors.smsTimeout?.message}</FormErrorMessage>
                                </FormControl>}/>
                                <Controller name="packTimeout" rules={{
                                    required: 'Это обязательное поле',
                                }} control={control} render={({field}) => <FormControl isInvalid={!!errors.smsTimeout}
                                                                                       className="mt-4">
                                    <FormLabel className="!flex items-center gap-2">
                                        <Text>Задержка группы</Text>
                                        <Tooltip label="Задержка после отправки группы смс. Измеряется в минутах">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </FormLabel>
                                    <InputGroup>
                                        <NumberInput focusBorderColor="teal.200" className="w-full"
                                                     min={1} value={field.value} onChange={field.onChange}
                                                     allowMouseWheel clampValueOnBlur>
                                            <NumberInputField/>
                                            <InputRightElement className="flex items-center justify-center !h-full">
                                                <TimeIcon className="!w-6 !h-6"/>
                                            </InputRightElement>
                                        </NumberInput>
                                    </InputGroup>
                                    <FormErrorMessage>{errors.smsTimeout?.message}</FormErrorMessage>
                                </FormControl>}/>
                            </Box>
                            <Box className="flex gap-1 max-sm:flex-col">
                                <Controller name="proxyIp" rules={{
                                    validate: (value, formValues) => (formValues.proxyPort.length || formValues.proxyPassword.length || formValues.proxyUsername.length) ? !value.length ? 'Обязательное поле' : /^((?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)\.){3}(1?\d?\d|25[0-5]|2[0-4]\d)|([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/.test(value) || 'Неверный формат IP' : true,
                                }} control={control} render={({field}) => <FormControl isInvalid={!!errors.proxyIp}
                                                                                       className="mt-4">
                                    <FormLabel>Прокси IP</FormLabel>
                                    <Input
                                        type="text"
                                        focusBorderColor="teal.200"
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                    <FormErrorMessage>{errors.proxyIp?.message}</FormErrorMessage>
                                </FormControl>}/>
                                <Controller name="proxyPort" rules={{
                                    validate: (value, formValues) => (formValues.proxyIp.length || formValues.proxyPassword.length || formValues.proxyUsername.length) && !value.length ? 'Обязательное поле' : true,
                                }} control={control} render={({field}) => <FormControl isInvalid={!!errors.proxyPort}
                                                                                       className="mt-4">
                                    <FormLabel>Прокси порт</FormLabel>
                                    <NumberInput focusBorderColor="teal.200" className="w-full"
                                                 min={0} max={65535} value={field.value} onChange={field.onChange}
                                                 allowMouseWheel clampValueOnBlur>
                                        <NumberInputField/>
                                    </NumberInput>
                                    <FormErrorMessage>{errors.proxyPort?.message}</FormErrorMessage>
                                </FormControl>}/>
                            </Box>
                            <Box className="flex gap-1 max-sm:flex-col">
                                <Controller name="proxyUsername" rules={{
                                    validate: (value, formValues) => (formValues.proxyPort.length || formValues.proxyPassword.length || formValues.proxyIp.length) && !value.length ? 'Обязательное поле' : true,
                                }} control={control}
                                            render={({field}) => <FormControl isInvalid={!!errors.proxyUsername}
                                                                              className="mt-4">
                                                <FormLabel>Прокси логин</FormLabel>
                                                <Input
                                                    type="text"
                                                    focusBorderColor="teal.200"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                                <FormErrorMessage>{errors.proxyUsername?.message}</FormErrorMessage>
                                            </FormControl>}/>
                                <Controller name="proxyPassword" rules={{
                                    validate: (value, formValues) => (formValues.proxyPort.length || formValues.proxyUsername.length || formValues.proxyIp.length) && !value.length ? 'Обязательное поле' : true,
                                }} control={control}
                                            render={({field}) => <FormControl isInvalid={!!errors.proxyPassword}
                                                                              className="mt-4">
                                                <FormLabel className="!flex items-center gap-2">Прокси
                                                    пароль</FormLabel>
                                                <Input
                                                    type="text"
                                                    focusBorderColor="teal.200"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                />
                                                <FormErrorMessage>{errors.proxyPassword?.message}</FormErrorMessage>
                                            </FormControl>}/>
                            </Box>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={closeModalAddHandler} mr={3} variant="ghost">Отмена</Button>
                            <Button type="submit" isLoading={isLoading}>Добавить</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddModel;
