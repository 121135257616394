import { FC, useState } from "react";
import { Box, Td, Text, Tr } from "@chakra-ui/react";
import ShowMessages from "./ShowMessages";
import AddMessage from "./AddMessage";
import EditModel from "./EditModel";
import DeleteModel from "./DeleteModel";
import { IModel } from "../models/IModel";
import { IMessage } from "../models/IMessage";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import cn from "classnames";
import AddParsing from "./AddParsing";
import SetActive from "./SetActive";
import ClearModelFollowers from "./ClearModelFollowers";

type Props = {
    model: IModel
    onSuccessAddMessage: (message: IMessage) => void
    onSuccessEdit: (model: {
        password: string,
        smsTimeout: number,
        packCount: number,
        packTimeout: number,
        username: string,
        proxyIp?: string
        proxyPort?: string,
        proxyPassword?: string,
        proxyUsername?: string,
    }) => void
    onSuccessDelete: () => void
    onSuccessParse: () => void
    setActiveHandler: (id: number, value: boolean) => void
    onSuccessClearFollowers: () => void
    onDeleteFile: (fileId: number) => void
}

const ModelRow: FC<Props> = ({
                                 model,
                                 onSuccessDelete,
                                 onSuccessEdit,
                                 onSuccessAddMessage,
                                 setActiveHandler,
                                 onSuccessParse,
                                 onSuccessClearFollowers,
                                 onDeleteFile
                             }) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Tr className={`[&>td]:px-1`}>
            <Td
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "text-green-400": model.messageGroup?.isProcessing,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}
            >{model.customNum}</Td>
            <Td className={cn({
                "text-red-400": model.passwordInvalid > 3,
                "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
            })}>{model.username}</Td>
            <Td textAlign="center" className={cn({
                "text-red-400": model.passwordInvalid > 3,
                "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
            })}>
                <Box className="flex items-center justify-between h-full gap-4 relative">
                    <Text className={cn({ "invisible": !showPassword })}>{model.password}</Text>
                    <Text className={cn("absolute left-0", { "hidden": showPassword })}>***********</Text>
                    {showPassword ?
                        <ViewOffIcon onClick={() => setShowPassword(prevState => !prevState)} w="20px" h="20px"
                                     className="cursor-pointer"/> :
                        <ViewIcon onClick={() => setShowPassword(prevState => !prevState)} w="20px" h="20px"
                                  className="cursor-pointer"/>}
                </Box>
            </Td>
            <Td textAlign="center"
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}
            >{model.unsubscribed}</Td>
            <Td textAlign="center"
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}>{model.followers}</Td>
            <Td textAlign="center"
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}>{model.smsTimeout} сек.</Td>
            <Td textAlign="center"
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}>{model.packCount} смс</Td>
            <Td textAlign="center"
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}>{model.packTimeout} мин.</Td>
            <Td textAlign="center"
                className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}>{model.proxyInvalid}</Td>
            <Td textAlign="center" className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}><SetActive
                model={model} setActiveHandler={setActiveHandler}/></Td>
            <Td className={cn({
                    "!bg-red-500 !border-red-500": model.proxyInvalid > 30,
                    "!bg-yellow-500 !border-yellow-500": model.isLockMonitoring,
                })}>
                <Box className="flex gap-2 items-center justify-end">
                    <ClearModelFollowers username={model.username} id={model.id} onSuccess={onSuccessClearFollowers}/>
                    <AddParsing modelId={model.id} onSuccess={onSuccessParse}/>
                    <ShowMessages messages={model.messageGroup} packCount={model.packCount}/>
                    <AddMessage modelPackCount={model.packCount}
                                videos={model.messageGroup?.files.filter(item => item.type === 'VIDEO') ?? []}
                                photos={model.messageGroup?.files.filter(item => item.type === 'PHOTO') ?? []}
                                defaultValue={model.messageGroup}
                                modelId={model.id}
                                onSuccess={onSuccessAddMessage}
                                onDeleteFile={onDeleteFile}/>
                    <EditModel {...model}
                               onSuccess={onSuccessEdit}/>
                    <DeleteModel username={model.username} id={model.id}
                                 onSuccess={onSuccessDelete}/>
                </Box>
            </Td>
        </Tr>
    );
};

export default ModelRow;
