import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        isPaused: false,
        maxActiveModels: 0,
        activeModels: 0,
        overallModels: 0,
    },
    reducers: {
        setSettings(state, payload: PayloadAction<{
            isPaused?: boolean,
            maxActiveModels?: number,
            activeModels?: number,
            overallModels?: number
        }>) {
            if (payload.payload.isPaused)
                state.isPaused = payload.payload.isPaused;
            if (payload.payload.maxActiveModels)
                state.maxActiveModels = payload.payload.maxActiveModels;
            if (payload.payload.activeModels)
                state.activeModels = payload.payload.activeModels;
            if (payload.payload.overallModels)
                state.overallModels = payload.payload.overallModels;
        },
    },
});

export const {setSettings} = settingsSlice.actions;

export default settingsSlice.reducer;
